import { useState } from "react";
import { Slide } from "react-awesome-reveal";
import { useRef, useEffect } from "react";
import Logo from "../../Assets/thebulb/bulblogo.svg";
import Logoblue from "../../Assets/thebulb/bulblogoblue.svg";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { navItems } from "./headerList";
import { LoginItems } from "./headerList";
// import { GetStarted } from "../GetStarted";
import { motion, Variants } from "framer-motion";
import { Marquee } from "../marquee/Marquee";

// type NavItem = {
//   label: string;
//   link?: string;
//   children?: NavItem[];
//   iconImage?: string;
// };

export const Header = ({ showDrops }) => {
  const [animationParent] = useAutoAnimate();

  const [isSideMenuOpen, setSideMenue] = useState(false);
  const parent = useRef(null);
  const [navColor, setNavColor] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 700) {
      setNavColor(true);
    } else {
      setNavColor(false);
    }
  };

  window.addEventListener("scroll", handleScroll);

  useEffect(() => {
    parent.current && animationParent(parent.current);
  }, [parent]);

  function openSideMenu() {
    setSideMenue(true);
  }
  function closeSideMenu() {
    setSideMenue(false);
  }
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <div>
      <Marquee />
      <div
        className={
          navColor
            ? "flex  bg-[#13162D] transition-all ease-in w-full shadow-lg md:px-12 px-6 justify-between items-center fixed z-[100] top-[50px] md:py-5 py-3 text-sm"
            : "flex  bg-[#e2e5f3] w-full md:px-12 border-b-[#989CB4] border-b-[1px] px-6 justify-between items-center fixed z-[100] top-[50px] md:py-5 py-3 text-sm"
        }
      >
        {/* left side */}

        <section>
          {/* logo */}
          <Link to="/" className="max-w-28 ">
            {navColor ? (
              <img src={Logo} alt="logo" />
            ) : (
              <img src={Logoblue} alt="logo" />
            )}
          </Link>
        </section>
        {/* middle side  */}
        <section ref={animationParent} className="flex items-center gap-10">
          {isSideMenuOpen && (
            <MobileNav closeSideMenu={closeSideMenu} showDrops={showDrops} />
          )}
          <div className="hidden lg:flex md:hidden items-center gap-4 transition-all">
            {navItems.map((d, i) => (
              <div
                key={i}
                className="relative group  px-2 py-3 transition-all "
              >
                <p
                  className={
                    navColor
                      ? "flex cursor-pointer items-center gap-2 text-[#fff] font-medium  text-[18px]  "
                      : "flex cursor-pointer items-center gap-2 text-primaryColor font-medium  text-[18px] "
                  }
                >
                  <span>{d.label}</span>
                  {d.children && (
                    <IoIosArrowDown className=" rotate-180  transition-all group-hover:rotate-0" />
                  )}
                </p>

                {/* dropdown */}
                {d.children && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    className="absolute   right-0   top-10 hidden w-auto  flex-col gap-1   rounded-lg bg-white py-3 shadow-md  transition-all group-hover:flex "
                  >
                    {d.children.map((ch, i) => (
                      <a
                        key={i}
                        href={ch.link ?? "#"}
                        // target="_blank"
                        className=" flex cursor-pointer items-center  py-1 pl-6 pr-8  text-primaryColor font-medium hover:text-black  "
                      >
                        {/* item */}
                        <span className="whitespace-nowrap   pl-3 ">
                          {ch.label}
                        </span>
                      </a>
                    ))}
                  </motion.div>
                )}
              </div>
            ))}

            {/* sub dropdown */}
          </div>
          {/* navitems */}
        </section>
        {/* right side data */}
        <section className=" hidden md:hidden lg:flex   items-center gap-8 ">
          <motion.button
            whileTap={{ scale: 0.67 }}
            onClick={showDrops}
            className={
              navColor
                ? "h-fit rounded-full font-Gotic px-[48px] py-[14px] bg-[#13162D] text-[#fff] hover:bg-[#FDBF17] hover:text-[#13162D] border-2 border-[#FDBF17] transition-all hover:border-0"
                : "h-fit rounded-full font-Gotic px-[48px] py-[14px] bg-[#13162D] border-2 shadow-lg text-[#fff] transition-all "
            }
          >
            Get Started
          </motion.button>
          <button
            ref={animationParent}
            className="h-fit text-primaryColor font-medium transition-all hover:text-black/90"
          >
            <div className="hidden md:flex items-center gap-4 transition-all">
              {LoginItems.map((d, i) => (
                <div
                  key={i}
                  // to={d.link ?? "#"}
                  className="relative group  px-2 py-3 transition-all "
                >
                  <p
                    className={
                      navColor
                        ? "flex cursor-pointer items-center gap-2 text-[#fff] font-medium  text-[18px] group-hover:text-black "
                        : "flex cursor-pointer items-center gap-2 text-primaryColor font-medium  text-[18px] "
                    }
                  >
                    <span>{d.label}</span>
                    {d.children && (
                      <IoIosArrowDown className=" rotate-180  transition-all group-hover:rotate-0" />
                    )}
                  </p>

                  {/* dropdown */}
                  {d.children && (
                    <div className="absolute   right-0   top-10 hidden w-auto  flex-col gap-1   rounded-lg bg-white py-3 shadow-md  transition-all group-hover:flex ">
                      {d.children.map((ch, i) => (
                        <a
                          key={i}
                          href={ch.link ?? "#"}
                          // target="_blank"
                          className=" flex cursor-pointer items-center  py-1 pl-6 pr-8  text-primaryColor hover:text-black  "
                        >
                          {/* item */}
                          <span className="whitespace-nowrap   pl-3 ">
                            {ch.label}
                          </span>
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}

              {/* sub dropdown */}
            </div>
          </button>
        </section>
        {navColor ? (
          <FiMenu
            onClick={openSideMenu}
            className="cursor-pointer text-white text-4xl lg:hidden md:block  block"
          />
        ) : (
          <FiMenu
            onClick={openSideMenu}
            className="cursor-pointer text-[#13162D]  text-4xl lg:hidden md:block block"
          />
        )}
      </div>
    </div>
  );
};

function MobileNav({ closeSideMenu, isSideMenuOpen, showDrops }) {
  return (
    <div className="fixed right-0 top-0 text-[#13162D]   h-screen z-[9999]   md:w-[400px] w-full  bg-white lg:hidden md:block block">
      <Slide
        direction="right"
      // keyframes={isSideMenuOpen ? "slideRightAndBack" : "slideBackLeft"}
      >
        <div className="  w-full   px-4 py-4">
          <section className="flex justify-end pt-12">
            <AiOutlineClose
              onClick={closeSideMenu}
              className="cursor-pointer text-black text-4xl "
            />
          </section>
          <div className=" flex flex-col text-xl font-bold pt-12 text-[#13162D]  gap-2 transition-all">
            {navItems.map((d, i) => (
              <SingleNavItem key={i} label={d.label} link={d.link}>
                {d.children}
              </SingleNavItem>
            ))}
          </div>

          <section className="  flex  flex-row pt-24  gap-4  mt-4 items-center">
            <button
              onClick={showDrops}
              className="h-fit rounded-full font-Gotic px-[48px] py-[14px] bg-[#e2e5f3] text-[#13162D] hover:bg-[#FDBF17] hover:text-[#13162D] border-2 border-[#FDBF17] transition-all hover:border-0 "
            >
              Get Started
            </button>
            <button className="h-fit text-[#13162D] font-bold text-xl transition-all hover:text-black/90">
              {LoginItems.map((d, i) => (
                <SingleNavItem key={i} label={d.label} link={d.link}>
                  {d.children}
                </SingleNavItem>
              ))}
            </button>
          </section>
        </div>
      </Slide>
    </div>
  );
}

function SingleNavItem(d) {
  const [animationParent] = useAutoAnimate();
  const [isItemOpen, setItem] = useState(false);

  function toggleItem() {
    return setItem(!isItemOpen);
  }

  return (
    <div
      ref={animationParent}
      onClick={toggleItem}
      // href={d.link ?? "#"}
      // target="_blank"
      className="relative   px-2 py-3 transition-all "
    >
      <p className="flex cursor-pointer items-center gap-2 text-[#13162D]  group-hover:text-black ">
        <span>{d.label}</span>
        {d.children && (
          // rotate-180
          <IoIosArrowDown
            className={`text-xs transition-all  ${isItemOpen && " rotate-180"}`}
          />
        )}
      </p>

      {/* dropdown */}
      {isItemOpen && d.children && (
        <div className="  w-auto  flex-col gap-1   rounded-lg bg-white py-3   transition-all flex ">
          {d.children.map((ch, i) => (
            <a
              key={i}
              href={ch.link ?? "#"}
              // target="_blank"
              className=" flex cursor-pointer items-center  py-1 pl-6 pr-8  text-[#13162D]  hover:text-black  "
            >
              <span className="whitespace-nowrap   pl-3 ">{ch.label}</span>
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
