import React from "react";
import homeicon from "../../Assets/thebulb/homeicon.svg";
import deskicon from "../../Assets/thebulb/deskicon.svg";
import engine from "../../Assets/thebulb/engineicon.svg";
import serviceicon from "../../Assets/thebulb/serviceicon.svg";
import communityicon from "../../Assets/thebulb/communityicon.svg";
import { motion } from "framer-motion";
export const BulbSolution = () => {
  return (
    <motion.div className=" px-6 lg:px-24 md:px-12 md:py-24  bg-[#F7F8FB] py-6 w-full">
      <h2 className="lg:text-6xl md:text-5xl text-3xl text-center pb-6 text-[#13162D]   font-bold ">
        Our Innovative Product Portfolio
      </h2>
      <p className=" text-[#4D4C4C] lg:text-2xl pb-6 md:text-xl text-center md:leading-[44px]">
        Building the next generation of Africa's dynamic leaders through
        technology
      </p>
      <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4 ">
        {SolutionData.map((solutions) => (
          <SolutionList
            key={solutions.id}
            solution={solutions.solution}
            solutionName={solutions.solutionName}
            icon={solutions.icon}
            link={solutions.link}
          />
        ))}
      </div>
    </motion.div>
  );
};

export const SolutionList = ({ solution, solutionName, icon, link }) => {
  return (
    <a href={link}>
      <div className="flex hover:cursor-pointer flex-col  bg-[#FFFF]  shadow-sm items-center justify-between xl:min-h-[300px] lg:min-h-[320px]  rounded-lg px-4 py-4 border border-[#13162D]">
        <img
          src={icon}
          alt=""
          width="80px"
          height="80px"
          className=" rounded-full bg-[#F7F8FB] text-center  p-4 "
          loading="lazy"
        />
        <h4 className="text-[#13162D] font-bold lg:text-[20px] py-4 text-[16px] ">
          {solutionName}
        </h4>
        <p className="text-[#4D4C4C] lg:text-[16px] text-[14px]  font-medium  ">
          {solution}
        </p>
      </div>
    </a>
  );
};

const SolutionData = [
  {
    id: "1",
    solution:
      "Empower African talent by equipping them with training for global opportunities and career advancement.",
    solutionName: "The Institute",
    icon: homeicon,
    // link: "https://thebulbafrica.institute/",
    link: "/institute"
  },
  {
    id: "2",
    solution: "Create your space to build, innovate, and collaborate. ",
    solutionName: "The Desk",
    icon: deskicon,
    link: "/co-workspace",
  },
  {
    id: "3",
    solution:
      "Secure top tech talent for your startup and product development needs.",
    solutionName: "Talent as a Service",
    icon: serviceicon,
    link: "/hire-talents",
  },
  {
    id: "4",
    solution:
      "Accelerate your startup journey with our programs and access co-investment solutions.",
    solutionName: "StartUp Engine",
    icon: engine,
    link: "/startup-engine",
  },
  {
    id: "5",
    solution:
      "Network, learn, and grow alongside a community of technology-driven individuals across Africa.",
    solutionName: "Communities",
    icon: communityicon,
    link: "/our-communities",
  },
];

export default SolutionData;
