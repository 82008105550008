import React, { useEffect, useState } from 'react';
import './App.css';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import rootReducers from './Reducer'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import Navigation from './Container/Navigation';
import './Assets/css/style.css'
import './Assets/css/fonts.css'
import CookieConsent from "react-cookie-consent";

const store = createStore(rootReducers, {},
	composeWithDevTools(
		applyMiddleware(thunk))
)





const App = () => {
	const toggleSave = () => localStorage.setItem('acceptCookies', true);
	const isSaved = localStorage.getItem('acceptCookies');

	return (
		<Provider store={store}>
			<Navigation />
			{!isSaved && < CookieConsent
				debug="true"
				onAccept={toggleSave}
				location="bottom"
				buttonText="Yes, I Accept."
				cookieName="myAwesomeCookieName2"
				style={{ background: "#10162b", border: "0.5px solid white" }}
				buttonStyle={{ background: "#fff", color: "#10162b", fontSize: "15px", borderRadius: "8px", height: "50px" }}
				expires={150}
			>
				<h3 style={{ fontSize: "15px", fontWeight: "bolder" }}>ACCEPTABLE USE OF YOUR DATA</h3>
				<br />
				<span style={{ fontSize: "15px" }}> Would you like to get more personalized content and improve your website experience? The process will require the processing of your personal data, such as your location and device, along with the use of cookies to access and store information on the device you are using. Your data belongs to you and you have the right to decide how to use it.
					<br /> <br />
					You can always change your preference or withdraw your consent anytime from the Cookie Declaration.
					To find out more about how we collect and use your data, see our <a style={{ color: '#fdd063' }} href='Privacy Policy The Bulb Website.pdf' >Privacy Policy</a>.{" "}</span>
			</CookieConsent>}
		</Provider>

	)
}


export default App;

