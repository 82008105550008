import React, { useState } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { heroData } from "./heroData";
import YouTube from "react-youtube";
import { MdArrowForwardIos } from "react-icons/md";
import { Slide, Fade } from "react-awesome-reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        border: "2px solid black",
        background: "transparent",
        color: "black",
        fontSize: "20px",
        width: "56px",
        height: "56px",
        borderRadius: "50%",
      }}
      onClick={onClick}
    >
      <MdArrowForwardIos />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        // width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        border: "2px solid black",
        background: "transparent",
        color: "black",
        fontSize: "20px",
        width: "56px",
        height: "56px",
        borderRadius: "50%",
      }}
      onClick={onClick}
    >
      <MdArrowBackIos />
    </div>
  );
}

export const Hero = () => {
  const options = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
      controls: 1,
      playbar: 0,
    },
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    
      <Slider {...settings}>
        {heroData.map((data) => (
          <div key={data.id}>
            <div className="  lg:px-24 md:py-12 lg:pt-[.8rem] manrope md:pt-[8rem]     py-12 w-full pt-[8rem]   lg:h-screen md:h-screen h-fit text-[#13162D]  md:bg-contain bg-contain  ">
              <div className="flex l  md:flex-row flex-col lg:h-screen h-fit  items-center md:gap-3 ">

              <div className="md:w-1/2 w-full lg:py-[2rem] ">
                <div className="flex flex-col items-start  md:gap-4 gap-4">
                  <Fade cascade damping={1e-1}>
                    <p className="md:text-[48px] text-2xl   ">
                      {data.header1}
                    </p>
                    <p className="lg:text-[56px] md:text-4xl text-3xl  lg:leading-[88px] md:leading-[56px]  font-bold ">
                      {data.header2}
                    </p>
                    <p className=" text-[#4D4C4C] md:block hidden lg:text-[24px]  md:text-xl md:leading-[44px]">
                      {data.text}
                    </p>
                  </Fade>
                  <a href={data.link}>
                    <button className="bg-[#FDBF17] w-full px-[40px] py-[14px] md:block hidden mt-2 rounded-full text-[#13162D]">
                      {data.button}
                    </button>
                  </a>
                </div>
              </div>
              <div className="md:w-1/2 w-full gap-6  justify-end flex ">
                {data.video ? (
                  <section className="col-span-1 md:w-full w-full min-h-full    md:pt-6  relative group">
                    <div className="border-t-[30px] border-b-[30px] rounded-lg  border-t-[#13162D] border-b-[#13162D]">
                      <div className="border-t-[5px] border-b-[5px]   border-t-[#fff] border-b-[#fff]">
                        <YouTube
                          id="youtube-video"
                          className="md:min-h-[350px] md:h-[350px] min-h-[270px] h-[270px] rounded-lg"
                          videoId={data.video}
                          opts={options}
                        />
                      </div>
                    </div>
                  </section>
                ) : (
                  <div className="">
                    <div className="border-y-[15px] border-x-[20px]  rounded-[20px]  border-[#13162D] ">
                      <div className="border-[5px] rounded-lg border-[#fff]">
                        <img
                          src={data.img}
                          loading="lazy"
                          alt=""
                          width="100%"
                          height="100%"
                          className="min-h-[270px] h-[270px] md:w-[580px] w-full  md:h-[400px]"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <p className=" text-[#4D4C4C] lg:text-[24px] py-4 md:hidden visible md:text-xl md:leading-[44px]">
                {data.text}
              </p>
              <a href={data.link} className="md:hidden visible w-full">
                <button className="bg-[#FDBF17] w-full px-[40px] py-[14px]  mt-2 rounded-full text-[#13162D]">
                  {data.button}
                </button>
              </a>
            </div>
          </div>
              </div>
        ))}
      </Slider>
    
  );
};
