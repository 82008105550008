import React, {useState} from "react";
import Logo from "../../Assets/thebulb/bulblogo.svg";
import addressIcon from "../../Assets/thebulb/addressicon.svg";
import phoneIcon from "../../Assets/thebulb/contacticon.svg";
import emailIcon from "../../Assets/thebulb/mailicon.svg";
import { Link } from "react-router-dom";
import { footerData } from "./FooterData";
import { Newsletter } from "../Newsletter";
import facebook from "../../Assets/footerLogo/facebook.png";

import twiter from "../../Assets/footerLogo/twitter.png";
import instagram from "../../Assets/footerLogo/instagram.png";
import discord from "../../Assets/footerLogo/discord.png";
import tiktok from "../../Assets/footerLogo/tiktok.png";
import linkdin from "../../Assets/footerLogo/linkidin.png";

export const Footer = ({initialRef}) => {
  return (
    <footer className="text-[#ffff] bg-primaryColor lg:px-24 md:px-12  px-6 md:py-10 py-8">
      <main className="flex md:flex-row flex-col md:gap-0 gap-8 justify-between w-full ">
        <div className="flex flex-col md:gap-6 gap-4 lg:w-[20%] md:w-[30%] w-full text-[14px] md:text-[16px]">
          <img src={Logo} alt="" width="148px" height="44px" />
          <div className="flex gap-4 items-center ">
            <img src={addressIcon} alt="" />
            <span>39 Ikorodu-Ososun Rd. Jibowu.Lagos</span>
          </div>
          <div className="flex gap-4 items-center ">
            <img src={phoneIcon} alt="" />
            <a href="">+234 700 834 2852</a>
          </div>
          <div className="flex gap-4 items-center ">
            <img src={emailIcon} alt="" />
            <a href="info@thebulb.africa">info@thebulb.africa</a>
          </div>
        </div>

        <div className="lg:w-[80%] md:w-[70%]  w-full flex  justify-end">
          <div className="grid lg:grid-cols-5 md:grid-col-3 grid-cols-2 gap-6 w-full  ">
            {footerData.map((data, id) => (
              <div className="flex flex-col gap-4" key={id}>
                <h1 className="font-bold md:text-[18px] text-[14px] ">
                  {data.label}
                </h1>
                <ul className="flex gap-2 flex-col text-[#E4E4E7] font-normal md:text-[14px] text-[12px]">
                  {data.children &&
                    data.children.map((child, id) => (
                  <>
                      <li className="" key={id}>
                        <a
                          href={child.link}
                          className="no-underline hover:underline"
                        >
                          {child.label}
                        </a>
                      </li>
                   <ul className="pl-4 ">
                    {
                      child.children && child.children.map((child, id)=>(
                        <li className="show-style py-1 cursor-pointer " key={id}>
                        <a>{child.label}</a>
                      </li>
                      ))
                    }
                   </ul>
                  </>
                      
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </main>
      <div>
        <Newsletter />
      </div>
      <div className="w-full h-[1px] bg-[#FDCE4E] "></div>
      <section className="flex md:flex-row flex-col-reverse md:gap-0 gap-6 md:items-center items-center  justify-between pt-6  ">
        <div className="text-[#E4E4E7] md:text-[16px] text-[12px] md:hidden block">
          <p>Copyright @ 2024 The Bulb All Rights Reserved</p>
        </div>

        <div className="flex  items-center no-underline gap-2 text-[#E4E4E7] md:text-[16px] text-[12px]">
          <Link to="/partner-with-us">Our Partners</Link>
          <span className="w-[1px] h-4 bg-[#FDBF17]"></span>
          <Link to="/terms-and-condition">Terms of Use</Link>
          <span className="w-[1px] h-4 bg-[#FDBF17]"></span>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <span className="w-[1px] h-4 bg-[#FDBF17] md:block hidden"></span>
          <Link
            to="https://thebulbafrica.medium.com/"
            className="md:block hidden"
          >
            Resources
          </Link>
        </div>

        <div className="text-[#E4E4E7] md:text-[16px] text-[12px] md:block hidden">
          <p>Copyright @ 2024 The Bulb All Rights Reserved</p>
        </div>

        <div className="text-[#E4E4E7] md:text-[16px] flex items-center md:flex-row flex-col text-[12px]">
          <p className="">Connect With Us</p>
          <ul className="flex md:gap-4 gap-2 items-center">
            <li>
              <a href="https://www.facebook.com/TheBulbAfrica/">
                <img src={facebook} alt="" width="40px" height="40px" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/thebulbafrica">
                <img src={twiter} alt="" width="40px" height="40px" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/thebulb.africa/">
                <img src={instagram} alt="" width="40px" height="40px" />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/v7Uzp9xvjg">
                <img src={discord} alt="" width="40px" height="40px" />
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@thebulbafrica?_t=8VICx62j0x4&_r=1">
                <img src={tiktok} alt="" width="40px" height="40px" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/64547766/">
                <img src={linkdin} alt="" width="40px" height="40px" />
              </a>
            </li>
          </ul>
        </div>
      </section>
    </footer>
  );
};
