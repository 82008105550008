import React from "react";
import { Slide } from "react-awesome-reveal";
import aboutLogo from "../../../Assets/thebulb/about.gif";

export const AboutBulb = () => {
  return (
    <div className="px-6 manrope lg:px-24 md:px-12 md:py-12 md:mt-12 mt-6 bg-[#F7F8FB] pt-6 w-full">
      <div className="flex md:flex-row flex-col gap-4 justify-between items-center">
        <div className="md:w-[65%] w-full ">
          <Slide direction="left" delay={0.5}>
            <div className="flex flex-col items-start gap-8">
              <h2 className="lg:text-[56px] md:text-4xl text-3xl text-[#13162D] md:leading-[72px]   font-bold ">
                We connect innovation from Africa to local and global
                opportunities.
              </h2>
              <p className=" text-[#4D4C4C] lg:text-[24px] md:text-[16px] lg:leading-[44px] ">
                We exist to inspire and empower the next generation of tech
                experts and innovators to build transferable and transportable
                skill set for the fast-changing global workspace.
              </p>
              <a href="https://www.thebulb.africa/about-us">
                <button className="bg-[#FDBF17] w-[173px] px-[40px] py-[14px] rounded-full  text-[#13162D]">
                  Learn More
                </button>
              </a>
            </div>
          </Slide>
        </div>
        <div className="md:w-[35%] justify-end flex  w-full">
          <Slide direction="right" delay={0.5}>
            <img src={aboutLogo} alt="" className="w-[392px]" loading="lazy" />
          </Slide>
        </div>
      </div>
    </div>
  );
};
